body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hero .app-logo {
    max-width: 300px;
    width: 300px;
}

img.mb-3.logo-small {
    height: 100%;
    width: 80px;
    margin-bottom: 0px!important;
    margin-right: 60px;
    margin-left: 40px;
}

.p-toast {
    opacity: 1!important;
}

.flex.gap-2 {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.p-toast-message {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.p-toast-message-content {
    padding-left: 35px!important;
}

.container button {
    margin: 11px;
}

.row > div {
    width: 13%;
    text-align: center;
}

iframe {
    width: 100%;
    height: 80vh;
}

span.p-input-icon-left {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex.justify-space-between {
    display: flex;
    justify-content: space-between;
}

.button_flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.projectmanagers_table {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 50px;
/*    border-top: 1px solid;*/
}

.projectmanager {
    display: flex;
    padding: 15px 0px 15px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid;
}

.pm_name, .pm_email {
    width: 300px;
    font-size: 16px;
    font-weight: bold;
    font-family: monospace;
}

.pm_name {
    width: 200px;
}

.pm_button button {
    width: 100px;
    border-radius: 30px;
    border: 0px;
    padding: 10px 0px;
}

.pm_button .edit {
    background: orange;
}

.pm_button .delete {
    background: red;
}

.pm_button {
    overflow: auto;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.addPM {
    background: green;
    border: 0px;
    padding: 10px 30px;
    border-radius: 30px;
    color: white;
}

button.download.btn.btn-secondary {
    width: 200px;
    background: white;
    color: black;
}

button.complete.btn.btn-secondary {
    width: 116px;
    color: white;
    background: orange;
    margin-top: 10px;
    border: 0px;
}

button.download {
    width: 65px;
}

.buttons_completed {
    width: 100px!important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

a.download {
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
    border-radius: 10px;
    padding: 5px 10px;
    width: 200px!important;
    color: black;

}

button.p-button.p-component.p-button-icon-only.p-button-outlined.p-button-raised.p-button-info {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0.25rem 0;
}
