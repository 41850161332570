
.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.rowTop div {
    width: 13%;
    text-align: center;
}

.error {
    text-align: center;
    padding-bottom: 10px;
    font-weight: bold;
    color: red;
    display: none;
}

.error.active {

    display: block;
}

.p-password.p-component.p-inputwrapper.p-input-icon-right {
    width: 100%;
}

.form_row.contractorDelete label button {
    margin-bottom: 0px;
}

.p-skeleton.p-component {
    display: none;
}

.p-card.p-component {
    margin: 20px 0px;
}

button.p-button.p-button-icon-only {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0.25rem 0;
}

.p-skeleton.p-component.active {
    display: block;
}

.dashboard {
    display: flex;
    flex-wrap: wrap;
    gap: 48px;
}

.p-card.p-component {
    width: 100%;
}

.p-menubar.p-component {
    padding: 10px 20px;
}

.p-menubar-start img {
    height: 56px;
}

.card.chart .p-skeleton {
    position: absolute;
    height: 100%!important;
    width: 100%;
    opacity: .9;
}

.p-datatable.p-component.p-datatable-responsive-scroll {
    width: 100%;
}

.p-datatable-row-expansion {
/*    background: rgba(0,0,0,.2)!important;*/
}

tr.p-datatable-row-expansion > td {
    padding-bottom: 100px!important;
    border-left: 2px solid black!important;
    border-right: 2px solid black!important;
    border-bottom: 2px solid black!important;
}

.submitButton {
    display: none;
}

.submitButton.active {
    display: inline-flex;
}

.card_stats_view {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 48px;
}

button.w-full.p-link.flex.align-items-center {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 30px 0px 0px;
    width: 200px;
}

a.attachment {
    display: none;
}

a.attachment.active {
    display: block;
    width: 160px;
    margin-left: 71px;
    margin-top: 10px;
}

.newJobModel {
    opacity: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(black, transparent);
    transition: all 500ms;
    -webkit-transition: all 500ms;
}

.status.approved button {
    background: green;
    margin: 20px 0px 50px;
    width: 300px;
    height: 100px;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
    /* border-color: #ccc; */
    border-style: solid;
    border-width: 14px 7px 0;
    content: "";
    display: flex;
    height: 9px;
    position: absolute;
    top: 6px;
    width: 9px;
    bottom: 6px;
    margin: auto;
    align-items: center;
    justify-content: center;
}

span.react-datepicker__navigation-icon.react-datepicker__navigation-icon--next {
    color: black!important;
    fill: black!important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filters {
    width: 96%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 0% 50px;
}

.form_row.notFull.filter {
    width: 20%;
}

.form_row.notFull.filter select {
    width: 100%;
}

.form_row.notFull.filter .form-label {
    width: 100%;
}

.filters div.filter_buttons {
    width: 10%;
}

.filters button.btn.btn-primary {
    width: 100%;
}

.filters {
    margin-bottom: 50px;
}

.newJobModel.active {
  opacity: 1;
  z-index: 100;
}

.p-password.p-component.p-inputwrapper.p-inputwrapper-filled.p-input-icon-right, .p-password.p-component.p-inputwrapper.p-inputwrapper-filled.p-input-icon-right input {
    width: 100%;
}

input.p-inputtext.p-component {
    width: 100%;
}

.newContractorForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100vh;
    overflow: scroll;
    /* margin-bottom: 100px; */
    padding: 0px 30px 50px;
}

.newContractorForm form {
     margin-top: 100px; 
}

.newContractorForm .formButtons {
     margin-bottom: 200px; 
}

.pm_comments_holder {
    width: 75%;
    border: 2px solid black;
    padding: 20px;
    margin: 20px 0px 50px;
    color: red;
}

.pm_comments_title {
    color: red;
    font-weight: bold;
    font-size: 30px;
}

.newJobModel > div {
    background: white;
    padding: 100px 50px;
    box-sizing: border-box;
    max-width: 100vw;
    width: 600px;
    border-radius: 32px;
    max-height: 90vh;
    overflow: scroll;
}

.newJobModel input, .newJobModel label {
    width: 100%;
}

a.PDFDownload {
    width: 250px;
    margin: 30px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: green;
    color: white;
    padding: 10px;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
    border-radius: 10px;
}

.formButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
    float: left;
    flex-wrap: wrap;
}

.modelTitle {
    font-weight: 700;
    font-size: 29px;
    text-align: center;
    margin-bottom: 20px;
}

.application_row.header.row {
    width: 100%;
    height: 40px;
    border-bottom: 2px solid;
}

.application_row .number {
    /* width: 150px; */
}

.application_row .name {
    /* width: 150px; */
}

.location {
    /* width: 150px; */
}

.retention {
    /* width: 150px; */
}

.application_row button {
    width: 5%;
    margin: 2.5%;
}

.application_row button {
    width: 60px;
    margin: 0% 20px;
}

button.hide.btn.btn-primary {
    display: none;
}

.application_row {
    width: 100%;
    /* height: 60px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.Jobs {
    margin-top: 60px;
}

.application_row.header.row {
    font-weight: 900;
    font-size: 15px;
}

.navbar-light.bg-light .nav-link {
    color: #041433;
}
.navbar {
    background-color: #eff1f5;
    padding: 1.375rem 0;
    box-shadow: 0 1px 2px 0 #dfe3ec;
}
.navbar .router-link-exact-active {
    color: #041433;
    border-bottom: 3px solid #0066f9;
}
.navbar-brand {
    margin-right: 60px;
}
.navbar-nav .nav-link {
    color: #041433;
    padding: 0 0 0.5rem 0;
    box-sizing: border-box;
    margin: 0 40px 0 0;
    font-weight: 400;
}
.navbar-nav .nav-link:hover {
    color: #000104;
}
.navbar-nav .btn {
    min-width: 145px;
}
.nav-user-profile {
    border-radius: 50% 50%;
    max-width: 3.785rem;
    height: auto;
    box-shadow: 0 0 4px 0 #b3bac7;
}
.nav-item.dropdown .dropdown-toggle {
    margin: 0;
}
.nav-item.dropdown .dropdown-name {
    font-weight: 600;
}
.nav-item.dropdown .router-link-exact-active {
    border: 0;
}
.nav-item.dropdown .dropdown-menu {
    box-shadow: 0 0 4px 0 #b3bac7;
}
.nav-item.dropdown .dropdown-header {
    border-bottom: 1px solid #b3bac7;
    font-size: 1rem;
    font-weight: 600;
    color: #041433;
}
.nav-item.dropdown .dropdown-item {
    border-bottom: 1px solid #b3bac7;
    padding: 0.55rem 1.5rem;
}
.nav-item.dropdown .dropdown-item .icon {
    margin-right: 8px;
    vertical-align: middle;
}
.nav-item.dropdown .dropdown-item:first-child:hover {
    background: inherit;
}
.nav-item.dropdown .dropdown-item:last-child {
    border-bottom: 0;
}
.navbar {
    padding: 1.375rem 0;
}
@media (min-width: 576px) {
    .container {
        max-width: inherit;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
.container {
    max-width: 96%;
}
}
@media (max-width: 767px) {
    .nav-item {
        margin-bottom: 1rem;
    }
    .nav-item .nav-link {
        padding: 0 0 0 16px;
    }
    .nav-item .nav-link.router-link-exact-active {
        border-bottom: 0;
        border-left: 3px solid #0066f9;
        padding-left: 13px !important;
        vertical-align: middle;
    }
    .navbar-brand {
        margin-left: 2.5rem;
        vertical-align: top;
    }
    .navbar-toggler {
        margin-right: 2.5rem;
    }
    .navbar-nav {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
    .navbar-nav:first-child {
        margin-top: 1.5em;
    }
    .navbar-nav:last-child {
        background: #fff;
        box-shadow: 0 -1px 2px 0 #dfe3ec;
        margin: 1.5em 0 0 0;
        padding: 1.5em 2.5rem;
    }
    .navbar-nav:last-child li {
        margin-bottom: 1em;
    }
    .navbar-nav:last-child li .icon {
        margin-right: 1em;
        vertical-align: middle;
    }
    .navbar-nav:last-child li a {
        vertical-align: middle;
        color: #041433;
    }
    .navbar-nav .user-info img {
        margin-right: 1em;
    }
    .navbar-nav .btn-link {
        padding: 0;
        color: #041433;
        min-width: 0;
    }
}

.p-card-content {
    font-size: 33px;
    color: black;
}
.logo {
    background-image: url('./assets/logo.png');
    width: 150px;
height: 62px;
background-repeat: no-repeat;
background-size: contain;
}
footer {
    background: #eff1f5;
    box-shadow: 0 -1px 2px 0 #dfe3ec;
    min-height: 132px;
    text-align: center;
    padding: 32px;
}
footer .logo {
    margin: 0 auto 10px auto;
}
.hero {
    max-width: 500px;
    margin: 0 auto;
}
.hero p.lead {
    margin-bottom: 10rem;
    font-size: 1.45rem;
}
.hero p.lead a {
    font-weight: 500;
}
.hero .app-logo {
    max-width: 7.5rem;
}
.next-steps {
    padding: 0 32px;
}
.next-steps h2 {
    margin-bottom: 4rem;
}
.next-steps .row {
    margin-bottom: 3rem;
}
@media (max-width: 768px) {
    .next-steps .row {
        margin-bottom: 0;
    }
    .next-steps h6 {
        margin-top: 1.5rem;
    }
}
pre {
    width: 100%;
}
.profile-header {
    text-align: center;
    margin-bottom: 3rem;
}
.profile-header .lead {
    font-size: 1.25rem;
}
.profile-header .profile-picture {
    box-shadow: 0 0 4px 0 #b3bac7;
    margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
    .profile-header {
        text-align: left;
    }
    .profile-header .profile-picture {
        margin-bottom: inherit;
    }
}
.result-block-container {
    position: relative;
    min-height: 300px;
}
.result-block {
    top: -10px;
    position: absolute;
    opacity: 0;
    transition: all 0.3s;
}
.result-block.show {
    opacity: 1;
    top: 0;
}
@media only screen and (min-width: 768px) {
    .result-block {
        min-width: 700px;
    }
}
html {
    font-size: 16px;
}
body {
    color: #041433;
}
body,
html {
    height: 100%;
    margin: 0;
    padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}
h6.muted {
    color: #6c757d;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 1.5rem;
    font-size: 1rem;
}
a {
    color: #0066f9;
}
.btn-primary {
    background-color: #0066f9;
    border: 1px solid #005ce0;
}
.btn {
    border-radius: 0.1rem;
}

button.btn {
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
    border-radius: 10px;
}

.status {
    text-transform: capitalize;
}

.status.rejected {
    color: red;
    font-weight: bold;
}

.status.approved {
    color: green;
    font-weight: bold;
}

.status.in_review {
    color: blue;
    font-weight: bold;
}

.status.started {
    color: black;
    font-weight: bold;
}

.status.unsubmitted {
    color: #17a2b8;
    font-weight: bold;
}

button.approve_or_comment {
    width: 103px;
    margin-left: 40px;
    margin-right: 0px;
    font-size: 12px;
}

button a, button a:hover {
    color: inherit;
    text-decoration: none;
}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.multiInputRow {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.lineitem_row {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    justify-content: space-between;
}

.form_row.full label {
    width: 100%;
}

.form_row label span {
    width: 100%;
}

label.medium_input textarea {
    width: 100%;
}

label.medium_input {
    width: 70%;
}

label.small_input {
    width: 18%;
}

label.small_input input {
    width: 100%;
}

label.small_input label {
    width: 100%;
}

label.small_input.form-label select {
    margin-top: 8px;
}

.react-datepicker__input-container input {
    margin-top: 0px;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    float: left;
}

.formDivider {
    border-top: 5px solid #000000;
    opacity: .1;
    margin: 50px 0px;
    width: 100%;
    border-radius: 5px;
    float: left;
}

.form_row.notFull {
    float: left;
    margin-right: 20px;
    margin-top: 25px;
    width: auto;
}

.btn {
    border-radius: 10px;
}

.section_title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 26px;
    border-bottom: 3px solid black;
    width: auto;
    display: inline;
    float: left;
}

select.form-select {
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

.form_row.submit {
    margin-bottom: 100px;
}

.form_row.full {
    margin-top: 24px;
}

.form_row {
    width: 100%;
    float: left;
    min-height: 1px;
}

button.btn.btn-success {
    float: left;
}

/* label.small_input.form-label div {
    height: 24px;
} */

.row {
    align-items: center;
    justify-content: flex-start;
}

.application_row.row {
    margin-top: 0px;
    margin-bottom: 20px;
    width: 100%;
    float: left;
    padding: 0px 0px 20px;
    border-bottom: 2px solid;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.arrow {
    display: none!important;
}

.application_row_header.row {
    margin-top: 0px;
    margin-bottom: 20px;
    width: 100%;
    float: left;
    padding: 0px 0px 20px;
    border-bottom: 1px solid;
    font-weight: bold;
    font-size: 20px
}

.row button.hide {
    width: 62px;
    opacity: 0;
}

/* .job_number, .status, .project_manager {
    text-align: center!important;
} */

.status {
    text-transform: capitalize;
}

.status.rejected {
    color: red;
    font-weight: bold;
}

.status.approved {
    color: green;
    font-weight: bold;
}

.status.in_review {
    color: blue;
    font-weight: bold;
}

.status.started {
    color: black;
    font-weight: bold;
}

.status.unsubmitted {
    color: #17a2b8;
    font-weight: bold;
}

.status button.btn-Lg {
    width: 250px;
    margin: 30px 30px;
}

.delete {
    position: absolute;
    right: 15%;
}

label.form-label input {
    margin-top: 8px;
}

.signature {
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,.8);
    top: 0;
    left: 0;
    position: fixed;
    z-index: -1;
    display: none;
}

.signature.active {
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.comments {
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,.8);
    top: 0;
    left: 0;
    position: fixed;
    z-index: -1;
    display: none;
}

.comments.active {
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.comments textarea {
    width: 300px;
    height: 250px;
}

.signature canvas {
    background: white;
    margin-bottom: 20px;
    border: 2px solid black;
}

.signatureInner {
    padding: 50px;
    background: white;
    border-radius: 30px;
}

.flex-grow-1.mt-5.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.Jobs {
    margin-top: 60px;
    width: 100%;
}

.form_row.half {
    width: 50%;
}

.form_row.retention {
    width: 30%;
}

.form_row.contractorDelete {
    width: 10%;
}

.newJobModel .form_row.full {
    margin-top: 10px;
}

.form_row.contractorRow {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.fullPage {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

button.submitButton.btn.btn-primary {
    display: none;
}

button.submitButton.active.btn.btn-primary {
    display: block;
}

.rowTop {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
    float: left;
    padding: 0px 0px 0px;
    /* border-bottom: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%!important;
}

button.viewContractors.btn.btn-success {
    width: 190px;
}

.rowBottom {
    transition: all 1000ms;
    -webkit-transition: all 1000ms;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px 50px 50px;
    box-sizing: border-box;
    float: left;
    width: 100%!important;
}

.rowBottom.active {
    transition: all 1000ms;
    -webkit-transition: all 1000ms;
    display: none;
}

.contractor_row {
    width: 100%;
    display: flex;
/*    align-items: flex-start;*/
/*    justify-content: flex-start;*/
}

.contractor_row_header {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.contractor_row_header.bottom div {
    border: 0px;
    padding-top: 12px;
}

.contractor_row div {
    margin: 0px 10px;
    min-width: 50px;
    width: 200px;
    text-align: left;
    border-bottom: 1px solid;
    padding: 10px 0px;
}

.contractor_row_header div {
    margin: 0px 10px;
    min-width: 50px;
    width: 200px;
    text-align: left;
    margin-bottom: 0px;
    font-weight: bold;
    border-bottom: 1px solid;
    padding-bottom: 6px;
}

.newContractorFormHolder {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    z-index: -1;
    opacity: 0;
    transition: all 500ms;
    -webkit-transition: all 500ms;
    top: 0;
    left: 0;
}

.pageButtons button {
    margin: 0px 20px;
}

.newContractorFormHolder.active {
    z-index: 1200;
    opacity: 1;
    transition: all 500ms;
    -webkit-transition: all 500ms;
}

button.btn.btn-secondary.btn-Lg.success {
    background: green;
}

.application_row_header.row div {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow {
    /* display: none; */
    opacity: 0;
}

.application_row_header.row.active .arrow {
    transform: rotate(180deg);
}

.current .arrow {
    opacity: 1;
}

.date, .invoice_value {
    /* width: 150px!important; */
}

.job_number {
    /* width: 150px!important; */
}

.p-card-content p {
    font-size: .75em;
}
