body {
  background-color: white;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.styles_container {
    width: 400px;
    height: 200px;
}

.sigContainer {
  background-color: #fff;
}

.sigPad {
  width: 403px;
  height: 200px;
}

.buttons {
  width: 100%;
  height: 30px;
}

.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}
